<template>
	<div>
		<div class="logo">
			<a href="/">
				<img src="/static/img/sole_tracking.png" alt="">
			</a>
		</div>
		<!-- ======= Register Section ======= -->
		<section id="register" class="register">
			<div class="container" v-if="resultadoRegistro === false">

				<div class="section-title">
					<h2>Registro</h2>
					<p>¿Ya tienes una cuenta?<router-link to="/login"><strong>Inicia Sesión</strong></router-link></a></p>
				</div>

				<div class="col-10 offset-1">
					<form role="form" id="formRegister" class="formRegister php-email-form" novalidate>
						<div class="row">
							<div class="form-group col-lg-6">
								<input placeholder="Nombre(s)" type="text" name="name" class="form-control" id="name" required v-model="formRegistro.usuario.nombre" />
								<div class="invalid-feedback">Campo obligatorio</div>
							</div>
							<div class="form-group col-lg-6">
								<input placeholder="Apellidos" type="text" name="apellidos" class="form-control" id="apellidos" required v-model="formRegistro.usuario.apellidos" />
								<div class="invalid-feedback">Campo obligatorio</div>
							</div>
						</div>

						<div class="row">
							<div class="form-group col-lg-6">
								<select class="form-control" id="selectGenero" name="selectGenero" required v-model="formRegistro.usuario.genero">
									<option value="">Género</option>
									<option value="Mujer">Mujer</option>
									<option value="Hombre">Hombre</option>
									<option value="Otro">Otro</option>
								</select>
								<div class="invalid-feedback">Campo obligatorio</div>
							</div>
							<div class="form-group col-lg-6">
								<input placeholder="Teléfono" type="text" name="telefono" class="form-control" id="telefono" required v-model="formRegistro.usuario.telefono" />
								<div class="invalid-feedback">Campo obligatorio</div>
							</div>
						</div>

						
						<div class="form-group">
							<input placeholder="Correo" type="email" class="form-control" name="email" id="email" data-rule="email" required v-model="formRegistro.usuario.correo" />
							<div class="invalid-feedback">Campo obligatorio</div>
						</div>
						<div class="row">
							<div class="form-group col-lg-6">
								<input placeholder="Contraseña" type="password" class="form-control" name="password1" id="password1" required />
								<div class="invalid-feedback">Campo obligatorio</div>
							</div>
							<div class="form-group col-lg-6">
								<input placeholder="Confirmar contraseña" type="password" name="password2" class="form-control" id="password2" required v-model="formRegistro.usuario.contrasena" />
								<div class="invalid-feedback">Campo obligatorio</div>
							</div>
						</div>
						<div class="form-check">
							<input type="checkbox" class="form-check-input" id="terminoCondiciones">
							<label class="form-check-label" for="terminoCondiciones"> He leído y acepto los <a href="#" target="_blank"><strong>Términos y Condiciones</strong></a></label>
						</div>
						<div class="text-center">
							<button id="registrar" v-on:click="funcionRegistrar()" type="button" >Registrarme</button>
						</div>
					</form>
				</div>
			</div>
			<div class="container" v-else>
				<div class="section-title">
					<h2>Registro completado</h2>
					<p>Se ha enviado un correo de verificación a la cuenta <strong>{{formRegistro.usuario.correo}}</strong></p>
				</div>
			</div>
		</section><!-- End Register Section -->
	</div>		
</template>

<script> 
	export default { 
		data(){ 
			return {
				formRegistro: {
					familia: {
						nombreEncargado: "",
						apellidos_familia: "",
						correo: "",
						telefono1: ""
					},
					usuario: {
						nombre: "",
						apellidos: "",
						genero: "",
						telefono: "",
						correo: "",
						contrasena: ""
					}					 
				},
				resultadoRegistro: false
			}
		}, 
		created() { 
			//var instance = this;
		}, 
		methods: { 
			/*Funcion que esta conectada a la funcion de*/
			funcionRegistrar: function(){
				var instance = this;
				var form = document.getElementById('formRegister');

				if (form.checkValidity() === false) {
		          console.log("VALIDITY = FALSE")
		        }else{
		        	instance.formRegistro.familia.nombreEncargado = instance.formRegistro.usuario.nombre
		        	instance.formRegistro.familia.apellidos_familia = instance.formRegistro.usuario.apellidos
		        	instance.formRegistro.familia.correo = instance.formRegistro.usuario.correo
		        	instance.formRegistro.familia.telefono1 = instance.formRegistro.usuario.telefono
		        	
		        	axios.request({
						url: '/api/registrarme',
						method: 'post',
						data: instance.formRegistro
					}).then(function(response){
						if (response.data.status) {
							console.log("Info enviada!");
							instance.resultadoRegistro = true;
						}else {
							console.log("Error!");
						}
					}).catch(function(error){
						console.log("Intenta de nuevo!");
					});
		        }
		        form.classList.add('was-validated');		        
			},

		} 
	} 
</script>
<style scoped type="text/css" src="../../assets/css/style.css"></style>
